<template>
  <div class="user-purchase-section">
    <headerCustom></headerCustom>
    <div class="profile-section main-content">
      <div class="heading-sec">
        <div class="row">
          <div class="col-md-4 column">
            <div class="heading-profile">
              <h3>Transaction</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- title section end -->
      <div>
        <b-container>
          <b-row>
            <b-col md="10">
              <div class="user-profile-invoice-edit">
                <div>
                  <div class="d-flex align-items-center pt-3">
                    <div class="title">Invoice</div>
                    <div class="package-dpd w-25 pt-2">
                      <b-form-group>
                        <b-form-select
                          v-model="invoice.package"
                          :options="packages"
                        ></b-form-select>
                      </b-form-group>
                    </div>
                    <div class="ml-auto">
                      <router-link :to="'/viewUserDetail/' + id">
                        <b-button class="btn btn-sm bg-gray shadow-none border-0 mr-4 px-5"
                          >Back</b-button
                        >
                      </router-link>
                    </div>
                  </div>
                  <div class="invoice-form-section pt-0">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                          <tr class="roboto-bold">
                            <th>SR.NO.</th>
                            <th>DATE OF PURCHASE</th>
                            <th>INVOICE NO.</th>
                            <th>DOWNLOAD</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in invoices" :key="item.key">
                            <td>{{ index + 1 }}</td>
                            <td>
                              {{ item.hotel.createdAt | moment("DD MMMM YYYY") }}
                            </td>
                            <td>{{ item.paymentId }}</td>
                            <td>
                              <div class="dark-blue pointer">
                                <div @click="downloadInvoicePdf(item._id)">
                                  Download
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div
                      class="table-body-contents text-center m-3 font-size-md
                    font-weight-bold text-muted"
                      v-if="invoices.length === 0"
                    >
                      No Data Found
                    </div>
                    <div class=" pagination-section text-center" v-if="invoices.length >= 1">
                      <b-pagination
                        class="mb-0 float-right"
                        v-model="page"
                        align="center"
                        :total-rows="totalCount"
                        :per-page="formData.limit"
                        @input="getInvoiceList(page)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import img1 from "@/assets/images/logo.png";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      id: this.$route.params.id,
      invoice: {
        package: "null"
      },
      packages: [
        { value: "null", text: "Packages" },
        { value: "a", text: "Advertisements" },
        { value: "a", text: "Deals & Offers" }
      ],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      invoices: [],
      user: ""
    };
  },
  created() {
    this.getInvoiceListForAdmin(this.page);
  },
  methods: {
    getInvoiceListForAdmin(pageNo) {
      this.invoices = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      (this.formData.user = this.$route.params.id),
        service.getInvoiceListForAdmin(this.formData, data => {
          if (data.status === 200) {
            this.invoices = data.data.result;
            this.totalCount = data.data.TotalCount;
          }
        });
    },
    downloadInvoicePdf(transactionId) {
      var obj = {
        id: transactionId
      };
      service.downloadInvoicePdf(obj, result => {
       
        var pdfUrl = result.data.filename;
        window.open(pdfUrl);
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.user-purchase-section {
  .user-profile-invoice-edit {
    background: $white;
    .title {
      color: $pink;
      font-size: $font-24;
      font-family: $futuraStd-medium;
      padding: 0.5rem 1.4rem;
    }
    .custom-dropdown {
      padding: 3px !important;
      background: none !important;
      margin-top: 15px;
      margin-left: 3rem;
    }
    .invoice-form-section {
      padding: 1.5rem 1.4rem;
      min-height: 100vh;
      .upload-btn-section {
        padding: 1.9rem 0rem;
        .btn-secondary {
          width: 60% !important;
          &:hover {
            background: $purple;
          }
        }
      }
      .invoice-edit,
      .invoice-save {
        .btn-secondary {
          width: 100% !important;
          padding: 0.3rem 6rem;
          &:hover {
            background: $purple;
          }
        }
      }
    }
  }
  .form-control {
    height: calc(1em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
  }
  .custom-select {
    height: calc(1.3em + 0.75rem + 2px);
    box-shadow: none;
  }
  .form-check {
    padding-top: 2.7rem;
  }
}
</style>
